module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://98a51c6562a740f888819c2567b5db5b@sentry.dev.az.dasha.ai/21","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon-96.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1161b8a0f11091060b4133a394427c8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en-us","ru-ru"],"defaultLanguage":"en-us"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}},"gatsby-remark-lazy-load",{"resolve":"/app/plugins/gatsby-remark-shiki/dist/index.js","options":{"theme":"./gatsby/code-theme.json","langs":[{"id":"dsl","scopeName":"source.dashaScriptingLanguage","grammar":{"version":"0.0.1","scopeName":"source.dashaScriptingLanguage","fileTypes":["dsl"],"foldingStartMarker":"\\{\\s*$","foldingStopMarker":"^\\s*\\}","patterns":[{"name":"comment.line.double-slash.dsl","match":"//.*$"},{"name":"comment.block.dsl","begin":"/\\*","end":"\\*/"},{"name":"constant.numeric.decimal.dsl","match":"[+-]?(\\d+?|\\d*\\d+)([eE]\\d+)?"},{"name":"constant.language.dsl","match":"true|false"},{"name":"string.quoted.double.dsl","begin":"\"","end":"\"|(?<!\\\\)(?=]\\s*\\n)"},{"name":"keyword.other.dsl.directive","match":"\\b(library|import|context)\\b"},{"name":"keyword.other.dsl.definition.toplevel","match":"\\b(start|node|block|digression|preprocessor|external|function|type)\\b"},{"name":"keyword.other.dsl.section","match":"\\b(do|transitions|onexit|digression|condition)\\b"},{"name":"keyword.other.dsl.transtion","match":"\\b(on|goto|priority)\\b"},{"name":"keyword.control.dsl","match":"\\b(if|on|goto|wait|done|return|blockcall|set|var)\\b"},{"name":"storage.type.dsl","match":"\\b(string|number|boolean)\\b"},{"name":"variable.other.dsl.context","match":"\\$[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.language.dsl.syscall","match":"#[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.other.dsl.literal","match":"@[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.other.dsl.local","match":"[a-zA-Z_][a-zA-Z0-9_]*"}]}}]}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "lazysizes"
import "@splidejs/splide/dist/css/themes/splide-default.min.css"

import "./static/Montserrat/index.css"
import "./static/consolas/index.css"
import "./static/OpenSans/index.css"
